const brandColors = {
  primary: '#0F0F0F',
  secondary: '#FBC61F',
};

const sidebarColors = {
  backgroundColor: '#E5F7D8',
  backgroundImage: 'url(./assets/sidebarBackground.png)',
  color: '#FFFFFF',
  dotfileLogo: '#ffffff',
};

const linkColors = {
  color: '#0000ED',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
