import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: BaseStepIdEnum.individual_edit,
  },
  {
    id: 'address_details',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'street_address',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'street_address_2',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'postal_code',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'city',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'country',
          nested: 'address',
          type: FieldTypeEnum.country,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'accomodation_type',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: ['owner', 'tenant', 'residing_rentfree'],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'additional_personal_details',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'professional_category',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'farmer',
            'craftsperson',
            'merchant',
            'worker',
            'employee',
            'intermediate_profession',
            'corporate_executive',
            'public_sector_executive',
            'artistic_profession',
            'scientific_profession',
            'business_owner',
            'liberal_profession',
            'military_personnel',
            'retiree',
            'unemployed',
            'student',
            'other_profession',
          ],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'business_sector',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'Digital Assets - Token Issuers (ICO)',
            'Digital Assets - Service Providers (DASP)',
            'Government or Public Enterprises',
            'Aerospace',
            'Stockbroker',
            'Agriculture',
            'Alcohol or Tobacco',
            'Food Industry',
            'Animation',
            'Animals',
            'Architecture',
            'Interior Design',
            'Armament or Defense',
            'Crafts',
            'Antiques / Art Market',
            'Insurance',
            'Audiovisual',
            'Audit / Management / Accounting',
            'Banking / Finance',
            'Construction / Public Works',
            'Jewelry / Watches',
            'Biology / Chemistry',
            'Phone Cards',
            'Strip Clubs',
            'Automotive Trade',
            'Retail (bars, alcohol, tobacco, etc.)',
            'Retail (non-textile)',
            'Retail (fashion and textiles, non-luxury)',
            'Wholesale (fashion and textiles, non-luxury)',
            'Gaming Industry (outside France)',
            'Auctioneers',
            'Communication / Information',
            'Accounting (excluding audit)',
            'Financial Investment Advisor (FIA)',
            'Cross-Border Banking (outside EEA)',
            'Cross-Border Banking (EEA zone)',
            'Culture / Entertainment',
            'Diamonds / Precious Metals',
            'Domiciliation Services',
            'Publishing / Printing',
            'Energy (excluding renewables)',
            'Renewable Energy',
            'Education / Training',
            'Environment / Cleaning',
            'Accounting / Audit',
            'Crowdfunding - Investment Advisors (CIA)',
            'Crowdfunding - Intermediaries (CI)',
            'Taxation',
            'Foreign Real Estate Investment Funds',
            'Wealth Management',
            'Retail Giants',
            'Hospitality / Catering',
            'Humanitarian Aid',
            'Real Estate (excl. rentals)',
            'Real Estate (rentals)',
            'Industry (excl. extraction, construction)',
            'Adult Entertainment',
            'Luxury Goods',
            'Extractive Industry',
            'IT / Telecom / Electronics',
            'Gambling and Casinos (France)',
            'Gambling and Casinos (non-France)',
            'Humanities / Social Sciences',
            'Public Procurement',
            'Mechanics / Maintenance',
            'Medical (excl. pharmaceuticals)',
            'Fashion and Textiles (excl. retail)',
            'Mutual Societies (EEA zone)',
            'Nightclubs',
            'Nightshops',
            'Digital / Multimedia',
            'Pharmacy',
            'Pornography',
            'Free Ports',
            'Pawnshops',
            'Provident Institutions (EEA zone)',
            'Legal Profession (lawyer)',
            'Legal Profession (excl. lawyer, tax advisor, notary)',
            'Legal Profession (notary)',
            'Human Resources',
            'Professional Retirement Organizations (EEA zone)',
            'Massage Parlors',
            'Health Products',
            'Health Services',
            'Security / Emergency Services',
            'Sciences / Mathematics / Physics',
            'Secretarial / Reception',
            'Social / Personal Services',
            'Aesthetic Care / Hairstyling',
            'Sports Sector',
            'Textile Retail',
            'Tourism',
            'Fund Transfers',
            'Transportation / Logistics',
            'Other Low-Risk Sectors',
            'No Specified Sector',
            'Illegal Activities (e.g., pedophilia, prostitution, drugs)',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'revenue_range',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'between_0_and_15k',
            'between_15k_and_30k',
            'between_30k_and_45k',
            'between_45k_and_60k',
            'between_60k_and_90k',
            'between_90k_and_150k',
            'more_than_150k',
          ],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'estimated_volume_of_transactions',
          type: FieldTypeEnum.number,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
          addons: {
            left: 'EUR (€)',
          },
        },
      ],
    },
  },
  {
    id: 'final_confirmation',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'consent_given',
          type: FieldTypeEnum.checkbox,
          isRequired: true,
          forceAllChecked: true,
          isEnabled: true,
          options: ['terms_and_conditions', 'agreement_to_share_documents'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
];
